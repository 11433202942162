import React from 'react';
import {
	AppIcon,
	AtIcon,
	CalendarIcon,
	ClockStopwatchIcon,
	MapPinIcon,
	TargetIcon,
	TruckRightIcon,
} from '../SVGIcons/SVGIcons';
import { dateConverter, parseTrackingStatus, timeDifference, timestampConverter } from '../../helpers';
import StopwatchCont from './StopwatchCont';
import MemberInfo from '../MemberInfo';

export default function PackageChoiceItem({ pack, callback, choice }) {
	console.log(pack);
	let addressString = pack?.address?.addressLine1
		? `${pack?.address?.name ? pack?.address?.name : ''}${
				pack?.address?.addressLine1 ? `, ${pack?.address?.addressLine1}` : ''
		  }${pack?.address?.county ? `, ${pack?.address?.county}` : ''}${
				pack?.address?.postalCode ? `, ${pack?.address?.postalCode}` : ''
		  }`
		: 'N/A';

	return (
		<div
			className={`packageChoiceItem${choice ? ' choice' : ''}`}
			onClick={() => {
				callback ? callback({ name: pack?.packageRef, value: pack?.packageUuid }) : null;
			}}>
			<div className='leftCol'>
				<div className='cardTitle'>
					<h4>{pack?.packageRef}</h4>
				</div>
				{addressString ? (
					<p>
						<TargetIcon iconClass='dataIcon' />
						{addressString}
					</p>
				) : (
					<></>
				)}
				{pack?.recipient ? (
					<p>
						<AtIcon iconClass='dataIcon' />
						{pack?.recipient}
					</p>
				) : (
					<></>
				)}
				<p>
					<MapPinIcon iconClass='dataIcon' />
					{pack?.locations
						? `With ${parseTrackingStatus(
								pack?.locations[pack?.locations?.length - 1]?.status
						  )?.text?.toLocaleLowerCase()}`
						: ''}
				</p>
				{pack?.user ? <MemberInfo user={pack?.user} type={'listing'} /> : <></>}
			</div>
			<div className='rightCol'>
				<StopwatchCont pack={pack} />
				<div className='bottomCont'>
					<div className='packageDates'>
						<p>
							<CalendarIcon iconClass='dataIcon' />
							{dateConverter(pack?.quotedCollectionDateTime)}
						</p>
						<p>
							<TruckRightIcon iconClass='dataIcon' />
							{dateConverter(pack?.quotedDeliveryDateTime)}
						</p>
					</div>
					{/* <div className='courierLogo'>Logo</div> */}
				</div>
				{pack?.courier?.companyName}
			</div>
		</div>
	);
}
