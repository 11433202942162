import React from 'react';
import { integrationDataLabels } from '../../../helpers';

export default function OrderDtlyInfo(props) {
	const { order } = props?.props || {};
	let dentallyData = order?.integrations?.soe?.dentally?.appData;
	let dentallyLabels = integrationDataLabels('dentally');

	console.log(dentallyData);

	return (
		<div className='gridItemOrder dentally'>
			<div className='row highlight'>
				<h4 className='gridHeader'>Dentally</h4>
			</div>
			<div className='colCont'>
				{dentallyData &&
					Object.keys(dentallyData).map((key, index) => {
						if (dentallyLabels[key] && key !== 'appointments') {
							return (
								<div className='row' key={index}>
									<p className='text'>{dentallyLabels[key]}: </p>
									<div className='data bold'>{dentallyData[key]}</div>
								</div>
							);
						} else {
							return <React.Fragment key={index}></React.Fragment>;
						}
					})}
			</div>
			<div className='colCont'>
				{dentallyData?.appointments &&
					dentallyData.appointments?.map((appointment, key) => {
						if (appointment) {
							return (
								<React.Fragment key={key}>
									<div className='row'>
										<p className='text'>Patient name: </p>
										<div className='data bold'>{appointment.patient_name}</div>
									</div>
									<div className='row'>
										<p className='text'>Reason: </p>
										<div className='data bold'>{appointment.reason}</div>
									</div>
									<div className='row'>
										<p className='text'>Starts at: </p>
										<div className='data bold'>{appointment.start_time}</div>
									</div>
								</React.Fragment>
							);
						} else {
							return <React.Fragment key={key}></React.Fragment>;
						}
					})}
			</div>
		</div>
	);
}
